import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { IAppState } from '../store/states/app.state';
import { inject } from '@angular/core';

export const authMttsGuard: CanActivateFn = (route, state) => {
  const _store = inject(Store<IAppState>);
  const _router = inject(Router);
  let auth = false;
  _store.select(appState=>appState.authorize).subscribe((data)=>{
    if(data.authorize){
      auth = true;
    }else{
      _router.navigate(["/logout"]);
      auth = false;
    }
  });
  return auth;
};
