import { ESimulationData,SimulationDataAction } from "../actions/SimulationData.action";
import { initialStateSimulationData } from "../states/SimulationData.state";

export function simulationDataReducer(
  state= initialStateSimulationData,
  action: SimulationDataAction
) {
  if (action.type === ESimulationData.LOAD_SIMULATION_DATA_SUCCESS) {
    return {
      ...state,
      ...action.payload
    };
  } else {
    return state;
  }
}
