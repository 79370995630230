import { EMaintenanceInfo, MaintenanceInfoAction } from "../actions/Maintenance-Info.action";
import { initialStateMaintenanceInfo } from "../states/Maintenance-Info.state";

export function maintenanceInfoReducer(
  state= initialStateMaintenanceInfo,
  action: MaintenanceInfoAction
) {
  if (action.type === EMaintenanceInfo.LOAD_MAINTENANCE_INFO_SUCCESS) {
    return {
      ...state,
      ...action.payload
    };
  } else {
    return state;
  }
}
