import {
	AutenticatedActions,
	EAutenticated
} from "../actions/autenticated.action";
import { initialStateAutenticated } from "../states/autenticated.state";

export function autenticatedReducer(
	state = initialStateAutenticated,
	action: AutenticatedActions
) {
	switch (action.type) {
		case EAutenticated.LOAD_AUTENTICATED_SUCCESS: {
			const { data, canal } = action.payload;
			return {
				...state,
				data,
				canal,
				error: false
			};
		}
		case EAutenticated.LOAD_CLIENT_SUCCESS: {
			return {
				...state,
				client: { ...action.payload }
			};
		}
		case EAutenticated.LOAD_AUTENTICATED_FAIL: {
			return {
				...state,
				error: true
			};
		}
		case EAutenticated.LOAD_EMAIL: {
			return {
				...state,
				client: {
					...state.client,
					email: action.payload
				}
			};
		}
		default:
			return state;
	}
}
