import { Action } from "@ngrx/store";
import { IAutenticated, IClient } from "../states/autenticated.state";

export enum EAutenticated {
	LOAD_AUTENTICATED_SUCCESS = "[Autenticated] get Autenticated Success",
	LOAD_CLIENT_SUCCESS = "[Autenticated] get Client Success",
	LOAD_AUTENTICATED_FAIL = "[Autenticated] get Autenticated Failed",
	LOAD_EMAIL = "[Autenticated] load email"
}

export class LoadAutenticatedSuccess implements Action {
	readonly type = EAutenticated.LOAD_AUTENTICATED_SUCCESS;
	constructor(public payload: IAutenticated | any) {}
}
export class LoadClientSuccess implements Action {
	readonly type = EAutenticated.LOAD_CLIENT_SUCCESS;
	constructor(public payload: IClient) {}
}

export class LoadAutenticatedFailed implements Action {
	readonly type = EAutenticated.LOAD_AUTENTICATED_FAIL;
	constructor(public payload: any) {}
}

export class LoadEmail implements Action {
	readonly type = EAutenticated.LOAD_EMAIL;
	constructor(public payload: string) {}
}

export type AutenticatedActions =
	| LoadAutenticatedSuccess
	| LoadAutenticatedFailed
	| LoadClientSuccess
	| LoadEmail;
