import { createReducer, on, Action } from '@ngrx/store';
import { changeCategoryCode } from '../actions/categoryCode.action';

export const initialState = 4;

export const changeCategoryCodeReducer = createReducer(
  initialState,
  on(changeCategoryCode, (_state, {option})=> option)
);

export function categoryCodeReducer(state:number | undefined, action: Action) {
  return changeCategoryCodeReducer(state, action);
}
