export interface MaintenanceInfo {
	customerData: CustomerData;
	maintenanceList: MaintenanceList[];
}

export interface CustomerData {
	expirationDate: string;
  expirationDateFormatted: string,
	alternativeType: string;
	state: string;
	customerName: string;
	mobilePhone: string;
  email: string;
	callDays: string;
	actionCode: number;
	opportunityId: string;
	obligaciones: string[];
}

export interface MaintenanceList {
  categoryCode: number;
	loanPlanDescription: string;
	loanNumber: string;
	daysInArrears: number;
	outstandingBalance: number;
	numberOfPayments: number;
	duePayments: number;
	principalBalance: number;
	arrearsBeginningDate: string;
	stateAlternative: string;
	termsActual: TermsActual;
}


export interface TermsActual {
	regularInstallment: string;
  regularInstallmentClean: number;
	nextPaymentDate: string;
	annualNominalInterestRate: number;
	interestRateType: string;
	paymentFrequency: number;
	paymentFrequencyUnits: string;
  effectiveAnnualInterestRate: number;
  feeTypeDescription: string;
  newRateTypeDescription: string;
	reason: string;
	comments: string;
}


export const initialStateMaintenanceInfo: MaintenanceInfo = {
  customerData: {
      expirationDate: "",
      expirationDateFormatted: "",
      alternativeType: "",
      state: "",
      customerName: "",
      email: "",
      mobilePhone: "",
      callDays: "",
      actionCode: 0,
      opportunityId: "",
      obligaciones: [""]
  },
  maintenanceList: [
      { 
          categoryCode: 0,       
          loanPlanDescription: "",
          loanNumber: "",
          daysInArrears: 0,
          outstandingBalance: 0,
          numberOfPayments: 0,
          duePayments: 0,
          principalBalance: 0,
          arrearsBeginningDate: "",
          stateAlternative: "",
          termsActual: {
              regularInstallment: "",
              regularInstallmentClean: 0,
              nextPaymentDate: "",
              annualNominalInterestRate: 0,
              interestRateType: "",
              paymentFrequency: 0,
              paymentFrequencyUnits: "",
              effectiveAnnualInterestRate: 0,
              feeTypeDescription: "",
              newRateTypeDescription: "",
              reason: "",
              comments: ""
          }
      }
  ]

}
